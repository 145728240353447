import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

const COOKIES_AGE_Y = 5;
const COOKIES_AGE_S = COOKIES_AGE_Y*365*24*60*60;

const USER_CK = "username";
const USER_RN = "realname";
const USER_SN = "surnames";
const USER_RL = "userrole";
const USER_ID = "userid";

@Injectable()
export class UserGlobals {

    /*
    COOKIE MANAGEMENT ----------------------------------------------------------
    */

    readCookie(name: string) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }
        return null;
    }

    writeCookie(name: string,value: string) {
        document.cookie = `${name}=${value}; max-age=${COOKIES_AGE_S}`;
    }

    /*
    ----------------------------------------------------------------------------
    */

    username: string = null;
    realname: string = null;
    surnames: string = null;
    userrole: string = null;
    userid: string = null;

    constructor(
        private router: Router
    ) {
        // this.userIsChecked();
    }

    defineUser(
        username: string, 
        remember_me: boolean, 
        realname: string, 
        surnames: string, 
        userrole: string,
        userid: string
    ) {
        this.username = username;
        this.realname = realname;
        this.surnames = surnames;
        this.userrole = userrole;
        this.userid = userid;
        this.setLocal(username, realname, surnames, userrole, userid);
        if (remember_me) {
            this.setCookies(username, realname, surnames, userrole, userid);
        }
    }

    logOut() {
        this.username = null;
        this.realname = null;
        this.surnames = null;
        this.userrole = null;
        this.userid = null;
        this.emptyCookies();
        this.emptyLocal();
    }

    userIsChecked(): boolean {
        let mistaken = !this.username;
        if (mistaken) {
            if (this.localRegistered()) {
                mistaken = false;
                this.retrieveFromLocal();
            } else {
                if (this.cookiesRegistered()) {
                    mistaken = false;
                    this.retrieveFromCookies();
                }
            }
        }
        if (mistaken) {
            this.logOut();
            if (this.router.url != "/login") this.router.navigateByUrl('login');
        }
        return !mistaken;
    }

    localRegistered() {
        return localStorage.getItem(USER_CK)
            && localStorage.getItem(USER_CK) != '';
    }

    retrieveFromLocal() {
        this.username = localStorage.getItem(USER_CK);
        this.realname = localStorage.getItem(USER_RN);
        this.surnames = localStorage.getItem(USER_SN);
        this.userrole = localStorage.getItem(USER_RL);
        this.userid = localStorage.getItem(USER_ID);
    }

    cookiesRegistered() {
        let user_cookie = this.readCookie(USER_CK);
        return user_cookie && user_cookie != "";
    }

    retrieveFromCookies() {
        this.username = this.readCookie(USER_CK);
        this.realname = this.readCookie(USER_RN);
        this.surnames = this.readCookie(USER_SN);
        this.userrole = this.readCookie(USER_RL);
        this.userid = this.readCookie(USER_ID);
    }

    emptyLocal() {
        this.setLocal("", "", "", "", "");
    }

    emptyCookies() {
        this.setCookies("","","","", "");
    }

    setLocal(username: string, realname: string, surnames: string, userrole: string, userid: string) {
        localStorage.setItem(USER_CK, username);
        localStorage.setItem(USER_RN, realname);
        localStorage.setItem(USER_SN, surnames);
        localStorage.setItem(USER_RL, userrole);
        localStorage.setItem(USER_ID, userid);
    }

    setCookies(username: string, realname: string, surnames: string, userrole: string, userid: string) {
        this.writeCookie(USER_CK, username);
        this.writeCookie(USER_RN, realname);
        this.writeCookie(USER_SN, surnames);
        this.writeCookie(USER_RL, userrole);
        this.writeCookie(USER_ID, userid);
    }

}