import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";

const routes: Routes = [
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "home",
        redirectTo: "/",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
        data: {
          toolbarShadowEnabled: true,
        }
      },
      {
        path: "blog",
        children: [
          {
            path: "categorias",
            loadChildren: () =>
              import("./pages/sections/news/categorias/categorias.module").then((m) => m.CategoriasModule),
            data: {
              toolbarShadowEnabled: true,
            }
          },
          {
            path: 'entradas',
            loadChildren: () =>
              import("./pages/sections/news/list-news/list-news.module").then((m) => m.ListNewsModule),
            data: {
              toolbarShadowEnabled: true,
            }
          }
        ],
      },
      {
        path: "tcn",
        loadChildren: () =>
          import("./pages/sections/trabajaconnosotros/trabajaconnosotros.module").then((m) => m.TrabajaconnosotrosModule),
        data: {
          toolbarShadowEnabled: true,
        }
      },
      {
        path: "banners",
        loadChildren: () =>
          import("./pages/sections/news/banners/banners.module").then((m) => m.BannersModule),
        data: {
          toolbarShadowEnabled: true,
        }
      },
      {
        path: "edit-entry",
        loadChildren: () =>
          import("./pages/sections/news/conf-news/conf-news.module").then((m) => m.ConfNewsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "edit-curso",
        loadChildren: () =>
          import("./pages/sections/clases/config-clases/config-clases.module").then((m) => m.ConfigClasesModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "servicios",
        children: [
          {
            path: "categorias-servicios",
            loadChildren: () =>
              import("./pages/sections/servicios/categoriasservicio/categoriasservicio.module").then((m) => m.CategoriasservicioModule),
            data: {
              toolbarShadowEnabled: true,
            }
          },
          {
            path: 'lista-servicios',
            loadChildren: () =>
              import("./pages/sections/servicios/list-services/list-services.module").then((m) => m.ListServicesModule),
            data: {
              toolbarShadowEnabled: true,
            },
          }
        ],
      },
      {
        path: "confighome",
        loadChildren: () =>
          import("./pages/sections/config-home/config-home.module").then((m) => m.ConfigHomeModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "equipo",
        loadChildren: () =>
          import("./pages/sections/oficinas/list-oficinas/list-oficinas.module").then((m) => m.ListOficinasModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "destinos",
        loadChildren: () =>
          import("./pages/sections/oficinas/destinos/destinos.module").then((m) => m.DestinosModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "clases",
        loadChildren: () =>
          import("./pages/sections/clases/list-clases/list-clases.module").then((m) => m.ListClasesModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "program-types",
        loadChildren: () =>
          import("./pages/sections/training/list-types/list-types.module").then((m) => m.ListTypesModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "clientes",
        loadChildren: () =>
          import("./pages/sections/gallery/gallery/gallery-routing.module").then((m) =>m.GalleryRoutingModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "banners",
        loadChildren: () =>
          import("./pages/sections/banners/banners-routing.module").then((m) =>m.BannersRoutingModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./pages/sections/customers/customers-list/customers-list.module").then((m) => m.CustomersListModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "booking",
        children: [
          {
            path: ":action_mode/list-service-types",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/access-service/list-stypes/list-stypes.module").then((m) => m.ListStypesModule),
            data: {
              toolbarShadowEnabled: true
            },
          },
          {
            path: ":action_mode/list-services/:id",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/access-service/list-bservices/list-bservices.module").then((m) => m.ListBservicesModule),
            data: {
              toolbarShadowEnabled: true
            },
          },
          {
            path: ":tid/conf-service/:id",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/config-service/config-service.module").then((m) => m.ConfigServiceModule),
            data: {
              toolbarShadowEnabled: true
            },
          },
          {
            path: ":tid/conf-service",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/config-service/config-service.module").then((m) => m.ConfigServiceModule),
            data: {
              toolbarShadowEnabled: true
            },
          },
          {
            path: "list-bookings",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/list-bookings/list-bookings.module").then((m) => m.ListBookingsModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: "list-offers",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/list-offers/list-offers.module").then((m) => m.ListOffersModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: "list-agents",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/list-agents/list-agents.module").then((m) => m.ListAgentsModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: "analytics",
            loadChildren: () =>
              import("./pages/sections/BOOKING_MODULE/analytics/analytics.module").then((m) => m.AnalyticsModule),
            data: {
              toolbarShadowEnabled: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login/login.module").then((m) => m.LoginModule),
    data: {
      toolbarShadowEnabled: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
    BrowserModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
